
import { Despesa, DespesaBaixa, DespesaParcela } from "@/core/models/financeiro";
import { PessoaService } from "@/core/services/cadastros";
import { ContaCorrenteService, DespesaService, FluxoBancarioService, FormaPagamentoService, TipoDocumentoService } from "@/core/services/financeiro";
import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export default class CfgOfx extends Vue {
  filter = new Modelo();
  service = new DespesaService();
  fluxoBancarioService = new FluxoBancarioService();

  modelo = new OfxModelSugestao();
  ofxModelRetorno = new OfxModelRetorno();
  despesa:any = new Despesa();
  baixa = new DespesaBaixa();

  dialogDespesa:boolean = false;
  overlay: boolean = false; 
  arquivo = null;     
  sheet: boolean = false; 
  valid:boolean = true;
  totalLista = 0;
  loading:boolean = false;
  errorAlert:boolean = false;

  parcelas = [];
  contaCorrentes = [];
  tiposDocumentos = [];
  fornecedores = [];
  formasPagamento = [];

  filtrarParcela = {
    dataVencimentoInicial:"",
    dataVencimentoFinal:"",
    dataPagamentoInicial:"",
    dataPagamentoFinal:"",
    numeroDocumento:'',
    situacaoId:[1,2,3,4],
    conciliado:false,
    empresaId: 0,
  }

  options: any = {
    itemsPerPage: 10,
  };

  $refs!: {
    form: HTMLFormElement;
  }; 
    
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  headers = [
    { text: 'Nª parcela',value: 'parcela', sortable: false},
    { text: 'Valor', value: 'valor', sortable: false },
    { text: 'Vencimento', value: 'dataVencimento', sortable: false },
    { text: 'Valor pago', value: 'valorPago', sortable: false },
    { text: 'Data de PGT', value: 'dataPagamentoParcela', sortable: false },
    { text: 'Identificação Doc.', value: 'despesa.numeroDocumento', sortable: false },
    { text: 'Favorecido', value: 'despesa.fornecedor.nome', sortable: false },
    { text: 'Conciliado', value: 'conciliado', sortable: false },
    { text: '', value: 'actions', sortable: false },
  ];

  @Watch("despesa")
  Despesa() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation(); 
    } 
  }
 
  @Watch("options", { deep: true })
  async ObterParcelas(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns  } = this.options;   
    this.loading = true;   
    this.filtrarParcela.empresaId = await this.ObterEmpresaContaCorrente();

    this.service.ListarParcelas(page, itemsPerPage, sortBy,sortDesc,'',this.headers, this.filtrarParcela, '' , '', 'Baixas, Despesa.Fornecedor').then(
      res => {
        this.parcelas = res.data.items;
        this.totalLista = res.data.count;
        this.loading = false;
      }) 
  }
    
  CarregarDadosArquivo(){
    if(!this.arquivo || !this.filter.contaCorrenteId)
      return this.$swal("Aviso", "Campo obrigatório não preenchido.", "warning");
    this.loading = true;

    this.fluxoBancarioService.LerArquivoConciliacao(this.arquivo, this.filter.contaCorrenteId).then(
    (res) => { this.ofxModelRetorno = res.data; this.loading = false })
  }

  AtualizarDados(){
    this.fluxoBancarioService.AtualizarDadosArquivo(this.filter.contaCorrenteId, this.ofxModelRetorno).then(
      (res) => { this.ofxModelRetorno = res.data; this.loading = false })
  }
   
  Conciliar(ofx){   
    let itens = this.ofxModelRetorno.ofxModelsSugestoes.map((x) => {
    const despesaParcelaSugerida = x.despesaParcelaSugerida || {};
    const baixas = despesaParcelaSugerida.baixas || [];
    const ultimaBaixa:any = baixas.length > 0 ? baixas[baixas.length - 1] : {};
      return {
        parcelaId: despesaParcelaSugerida.id || 0,
        baixaId: ultimaBaixa.id || 0,
        valorBaixa: x.ofxParserModel.valor,
        fitId: x.ofxParserModel.transactionId,
        data: x.ofxParserModel.data,
        conciliado: x.conciliado,
        formaPagamentoId: x.formaPagamentoId
      };
   });
    
  this.fluxoBancarioService.Conciliar(itens, this.filter.contaCorrenteId).then(
    (res) => {
      this.$emit("salvou");
    },
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
        ofx.conciliado = false;
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
        ofx.conciliado = false;
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        ofx.conciliado = false;
      }
    }).finally(() => this.AtualizarDados()); 
  }
  
  async CadastrarDespesa() {
    if (this.$refs.form.validate()) {
      let modelo: any = await this.MontarDespesa();

      new DespesaService().Salvar(modelo).then(
        (res) => {
          this.errorAlert = true;
          this.CloseDialogDespesa();
        },
        (err)=> {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        }).finally(() => {
          this.AtualizarDados()
        });  
    }
  }

  async ObterEmpresaContaCorrente() {
    const response = await new ContaCorrenteService().ObterPorId(this.filter.contaCorrenteId);
    return response.data.empresaId;
  }

  async MontarDespesa() {
    let despesa: any = new Despesa();
      
    if (this.modelo.ofxParserModel) {
      const { data, valor, descricao, transactionId } = this.modelo.ofxParserModel;

      despesa = {id: 0,origemId: 3,empresaId: await this.ObterEmpresaContaCorrente(),tipoDocumentoId: this.despesa.tipoDocumentoId,numeroDocumento: this.despesa.numeroDocumento,fornecedorId: this.despesa.fornecedorId,dataEmissao: data,valor: valor,observacao: descricao,tipoId: 1,parcelas: []};
      const parcela: any = { despesaId: 0, parcela: 1, valor: valor, dataVencimento: data, situacaoId: 3, baixas: []};
      const baixa = { fitId: transactionId, conciliado: true, valor: valor, dataPagamento: data, contaCorrenteId: this.filter.contaCorrenteId,formaPagamentoId: this.baixa.formaPagamentoId};

      parcela.baixas.push(baixa);
      despesa.parcelas.push(parcela);
    }

    return despesa;
  }

  OpenDialogDespesa(item){
    this.modelo = item;
    this.dialogDespesa = true;
  }

  CloseDialogDespesa(){
    this.modelo = new OfxModelSugestao();
    this.despesa = new Despesa();
    this.dialogDespesa = false;
  }
 
  mounted() {
    new FormaPagamentoService().ListarTudo().then(
      (res) => { this.formasPagamento = res.data.items})

    new ContaCorrenteService().ListarTudo().then(
      (res) => { this.contaCorrentes = res.data.items });

    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'id, nome, nomeCnpjCpf', '').then(
      (res) => { this.fornecedores = res.data.items });

    new TipoDocumentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'id,nome', '').then(
      (res) => { this.tiposDocumentos = res.data.items },); 
  }  
}

  class OfxModelRetorno {
    public ofxModelsSugestoes:OfxModelSugestao[] = [];
    public empresaId:number = 0;
    public saldo:number = 0;
  }

  class OfxModelSugestao {
    public ofxParserModel = new OfxParserModel();
    public despesaParcelaSugerida = new DespesaParcela();
    public baixaId:number = 0;
    public conciliado:boolean = false;
    public dataPagamento: string= "";
    public valorPago:number = 0;
    public formaPagamentoId:number = 0;
  }

  class OfxParserModel {
    public descricao:string = "";
    public data:string = "";
    public tipo:string = "";
    public transactionId:string = "";
    public valor:number = 0;
  }

  class Modelo {
    contaCorrenteId:number = 0;
  }
